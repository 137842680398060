import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'static/css/Header.css';

import menu_icon from 'static/images/hamburger_menu.svg';

const navigation = [{
    name: 'home',
    uri: '/',
    isActive: location => location.pathname === '/'
}, {
    name: 'company',
    uri: '/company',
    isActive: location => location.pathname === '/company'
}, {
    name: 'solutions',
    uri: '/solutions',
    isActive: location => location.pathname.startsWith('/solution')
}, {
    name: 'news',
    uri: '/news',
    isActive: location => location.pathname === '/news'
}, {
    name: 'contact',
    uri: '/contact',
    isActive: location => location.pathname.startsWith('/contact')
}];

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_menu: false,
            location: window.location.pathname,
            scroll_position: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        this.setState({scroll_position: Math.max(0, window.pageYOffset)});
    }

    componentDidUpdate() {
        // when page changes, close hamburger menu and scroll to top
        if (window.location.pathname === this.state.location) return;
        window.scrollTo(0, 0);
        this.setState({location: window.location.pathname, show_menu: false});
    }

    renderNavigation() {
        const location = window.location;
        return navigation.map(link => {
            if (link.isActive(location)) return <span key={link.name} className='navLink active' onClick={() => this.props.history.push(link.uri + '?')}>{link.name}</span>;
            return <Link key={link.name} className='navLink' to={link.uri}>{link.name}</Link>;
        });
    }

    render() {
        return (
            <div className={'header' + (this.state.show_settings ? ' settingsOpen' : '') + (navigation[0].isActive(window.location) ? ' home' : '') + (this.state.scroll_position > window.innerHeight / 6 ? ' scrolled' : '')}>
                <div className='logoContainer'>
                    <div className='menu' onClick={() => this.setState(prev => ({show_menu: !prev.show_menu}))}>
                        <img src={menu_icon} alt='menu' />
                    </div>
                    <Link to='/'><div className='headerLogo' alt='PilotAI' /></Link>
                </div>
                <div className='navigation'>
                    {this.renderNavigation()}
                </div>
                <div className='headerRight' />
                <div className={'hamburgerMenu' + (this.state.show_menu ? ' open' : '')} onClick={() => this.setState({show_menu: false})}>
                    <div className='hamburgerContent'>
                        <div className='logoContainer'>
                            <div className='menu' onClick={() => this.setState(prev => ({show_menu: !prev.show_menu}))}>
                                <img src={menu_icon} alt='menu' />
                            </div>
                            <Link to='/'><div className='headerLogo' alt='PilotAI' /></Link>
                        </div>
                        <div className='hamburgerNav'>{this.renderNavigation()}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Header);
