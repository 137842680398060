import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/Modal.css';

import Button from 'components/Button';

export default class Modal extends Component {
    static propTypes = {
        is_visible: PropTypes.bool.isRequired,
        children: PropTypes.node,
        onClose: PropTypes.func
    };

    render() {
        const {
            children,
            is_visible,
            onClose
        } = this.props;

        return (
            <div className={'modal' + (!is_visible ? ' hidden' : '')}>
                {children}
                {onClose && <Button onClick={onClose} text='Close' />}
            </div>
        );
    }
}
