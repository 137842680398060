import React, { Component } from 'react';
import 'static/css/Partners.css';
import Image from 'components/Image';

import qualcomm_logo from 'static/images/partners/qualcomm.svg';
import synaptics_logo from 'static/images/partners/synaptics.svg';
import ambarella_logo from 'static/images/partners/ambarella.svg';
// import nxp_logo from 'static/images/partners/nxp.svg';

export default class Partners extends Component {
    render() {
        return (
            <div className='partners'>
                <div className='preText'>Our Hardware Partners</div>
                <div className='logos'>
                    <a className='logo ambarella' href='https://www.ambarella.com' target='_blank' rel='noopener noreferrer'><Image src={ambarella_logo} alt='Ambarella' /></a>
                    <a className='logo qualcomm' href='https://www.qualcomm.com' target='_blank' rel='noopener noreferrer'><Image src={qualcomm_logo} alt='Qualcomm' /></a>
                    <a className='logo synaptics' href='https://www.synaptics.com/' target='_blank' rel='noopener noreferrer'><Image src={synaptics_logo} alt='Synaptics' /></a>
                    {/* <a className='logo nxp' href='https://www.nxp.com' target='_blank' rel='noopener noreferrer'><Image src={nxp_logo} alt='NXP' /></a> */}
                </div>
            </div>
        );
    }
}
