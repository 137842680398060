import React, { Component } from 'react';
import 'static/css/NewsView.css';
import Image from 'components/Image';

import map_projection from 'static/images/news/more/map-projection.png';
import nxp from 'static/images/news/more/nxp.png';
import nxp_ai from 'static/images/news/nxp-ai.webp';
import palantir_edge from 'static/images/news/palantir-edge.jpg';
import panorama from 'static/images/news/more/panorama.jpg';
import qualcomm_edge from 'static/images/news/qualcomm-edge.png';
import qualcomm from 'static/images/news/more/qualcomm.png';
import semiconductor from 'static/images/news/more/semiconductor.png';
import synaptics_conferencing from 'static/images/news/synaptics-conferencing.jpg';
import wired_chip from 'static/images/news/wired-chip.webp';

export default class News extends Component {
    renderNewsItem(url, title, image, date) {
        return (
            <a className='newsItem' href={url} target='_blank' rel='noopener noreferrer'>
                <Image src={image} className='newsItemImage' />
                <div className='newsItemContent'>
                    <div className='newsItemTitle'>{title}</div>
                    {date && <div className='newsItemDate'>{date}</div>}
                </div>
            </a>
        );
    }

    render() {
        return (
            <div className='news'>
                <div className='newsHero'>
                    <div className='heroText'>
                        <div className='title'>News</div>
                        <div className='subtitle'>Keep up to date with the latest news about Pilot AI.</div>
                    </div>
                </div>
                <div className='newsContent'>
                    <h2>Featured Stories</h2>
                    <div className='featured'>
                        <div className='left'>
                            {this.renderNewsItem('https://www.edge-ai-vision.com/2021/11/synaptics-demonstration-of-smart-video-conferencing-on-the-edge/', 'Synaptics Demonstration of Smart Video Conferencing on the Edge', synaptics_conferencing)}
                            {this.renderNewsItem('https://www.wired.com/story/thanks-to-ai-these-cameras-will-know-what-theyre-seeing/', 'Thanks to AI, These Cameras Will Know What They’re Seeing', wired_chip)}
                        </div>
                        <div className='center'>
                            {this.renderNewsItem('https://blog.palantir.com/edge-ai-in-space-93d793433a1e', 'Palantir Edge AI in Space', palantir_edge)}
                        </div>
                        <div className='right'>
                            {this.renderNewsItem('https://developer.qualcomm.com/blog/fast-and-efficient-inference-edge-pilot-ai', 'Fast and Efficient Inference at the Edge with Pilot AI', qualcomm_edge)}
                            {this.renderNewsItem('https://www.nxp.com/company/blog/powerful-hardware-and-a-strong-software-ecosystem-help-layerscape-excel-at-ai:BL-POWERFUL-HARDWARE-LAYERSCAPE-EXCEl-AI', 'Powerful Hardware and a Strong Software Ecosystem Help Layerscape Excel at AI', nxp_ai)}
                        </div>
                    </div>
                    <h2>More Stories</h2>
                    <div className='more'>
                        {this.renderNewsItem('https://aws.amazon.com/blogs/machine-learning/introducing-aws-panorama-improve-your-operations-with-computer-vision-at-the-edge/', 'Introducing AWS Panorama – Improve your operations with computer vision at the edge', panorama, '12/02/2020')}
                        {this.renderNewsItem('https://www.youtube.com/watch?v=FvWzU6flSLk', 'Demonstrating map projections with Pilot AI on the Qualcomm Vision Intelligence Platform', map_projection, '11/25/2020')}
                        {this.renderNewsItem('https://www.edge-ai-vision.com/2020/07/qualcomm-brings-advanced-artificial-intelligence-and-machine-learning-capabilities-to-address-multiple-tiers-of-smart-cameras-with-new-system-on-chips/', 'Qualcomm Brings Advanced Artificial Intelligence and Machine Learning Capabilities to Address Multiple Tiers of Smart Cameras with New System-on-Chips', qualcomm, '7/8/2020')}
                        {this.renderNewsItem('https://www.edge-ai-vision.com/2018/11/nxp-semiconductors-demonstration-of-optimized-performance-and-memory-utilization-for-object-detection-on-i-mx-rt/', 'NXP Semiconductors Demonstration of Optimized Performance and Memory Utilization for Object Detection on i.MX RT', semiconductor, '11/2/2018')}
                        {this.renderNewsItem('https://www.nxp.com/company/about-nxp/nxp-delivers-embedded-ai-environment-to-edge-processing:NW-EMBEDDED-AI-EDGE-PROCESSING', 'NXP Delivers Embedded AI Environment to Edge Processing', nxp, '6/13/2018')}
                    </div>
                </div>
            </div>
        );
    }
}
