import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import 'static/css/app.css';
import 'static/css/shortcuts.css';

import Header from 'components/Header';
import Footer from 'components/Footer';
import AutomotiveView from 'views/AutomotiveView';
import AutomotiveDetailsView from 'views/AutomotiveDetailsView';
import CompanyView from 'views/CompanyView';
import ContactView from 'views/ContactView';
import FitnessView from 'views/FitnessView';
import FitnessDetailsView from 'views/FitnessDetailsView';
import GovernmentView from 'views/GovernmentView';
import HomeView from 'views/HomeView';
import NewsView from 'views/NewsView';
import SetTopBoxView from 'views/SetTopBoxView';
import SmartHomeView from 'views/SmartHomeView';
import SolutionsView from 'views/SolutionsView';
import SurveillanceView from 'views/SurveillanceView';
import TeleconferencingView from 'views/TeleconferencingView';
import NotFound from 'views/NotFound';

export default class App extends Component {
    render() {
        return (
            <BrowserRouter basename='/'>
                <div className='app'>
                    <Header />
                    <div className='contentContainer'>
                        <Switch>
                            <Route exact path='/' component={HomeView} />
                            <Route exact path='/company' component={CompanyView} />
                            <Route exact path='/contact' component={ContactView} />
                            <Route exact path='/news' component={NewsView} />
                            <Route exact path='/solutions' component={SolutionsView} />
                            <Route exact path='/solution/automotive' component={AutomotiveView} />
                            <Route exact path='/solution/settopbox' component={SetTopBoxView} />
                            <Route exact path='/solution/smarthome' component={SmartHomeView} />
                            <Route exact path='/solution/surveillance' component={SurveillanceView} />
                            <Route exact path='/solution/fitness' component={FitnessView} />
                            <Route exact path='/solution/government' component={GovernmentView} />
                            <Route exact path='/solution/homefitness' component={FitnessDetailsView} />
                            <Route exact path='/solution/teleconferencing' component={TeleconferencingView} />
                            <Route exact path='/solution/vehicles' component={AutomotiveDetailsView} />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </BrowserRouter>
        );
    }
}
