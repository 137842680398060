import React, { Component } from 'react';
import 'static/css/Footer.css';
import Image from 'components/Image';

import angellist_icon from 'static/images/social/angellist.svg';
import crunchbase_icon from 'static/images/social/crunchbase.svg';
import facebook_icon from 'static/images/social/facebook.svg';
import linkedin_icon from 'static/images/social/linkedin.svg';

export default class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='copyright'>&copy;2021 Pilot AI Labs, Inc.<span className='allRights'> All rights reserved</span></div>
                <div className='socials'>
                    <a href='https://angel.co/company/pilot-ai-labs' target='_blank' rel='noopener noreferrer'><Image className='al' src={angellist_icon} alt='AngelList' /></a>
                    <a href='https://www.crunchbase.com/organization/pilot-ai-labs' target='_blank' rel='noopener noreferrer'><Image className='cb' src={crunchbase_icon} alt='CrunchBase' /></a>
                    <a href='https://www.linkedin.com/company/pilot-ai-labs/' target='_blank' rel='noopener noreferrer'><Image className='li' src={linkedin_icon} alt='LinkedIn' /></a>
                    <a href='https://www.facebook.com/pilotailabs/' target='_blank' rel='noopener noreferrer'><Image className='fb' src={facebook_icon} alt='Facebook' /></a>
                </div>
            </div>
        );
    }
}
