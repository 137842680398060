export default {
    black: '#111111',
    darkgray: '#222222',
    gray: '#666666',
    lightgray: '#dddddd',
    silver: '#eeeeee',
    white: '#ffffff',
    blue: '#0c7195',
    skyblue: '#55c3ee',
    deepblue: '#4224d2',
    green: '#92cf51',
    red: '#ff4d58',
    purple: '#a36bc8',
    orange: '#ffb15b'
};
