import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/DemoCards.css';
import Button from 'components/Button';
import Image from 'components/Image';

export default class DemoCards extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        contents: PropTypes.array // like [{title: '', description: '', image: '', onClick: ''}]
    };

    render() {
        const { title, subtitle, contents } = this.props;
        return (
            <div className='demoCards'>
                <h1>{title}</h1>
                <div className='subTitle'>{subtitle}</div>
                <div className='cardsContainer'>{contents.map((content, index) => (
                    <div className='card' key={index} onClick={content.onClick}>
                        <div className='imageContainer'><Image src={content.image} alt={content.title} /></div>
                        <div className='textContainer'>
                            <div className='title'>{content.title}</div>
                            <div className='description'>{content.description}</div>
                            <Button text='View Demo' onClick={content.onClick} size='small' />
                        </div>
                    </div>
                ))}</div>
            </div>
        );
    }
}
