import React, { Component } from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import 'static/css/ContactForm.css';

import Button from 'components/Button';

export default class ContactForm extends Component {
    static propTypes = {
        page_name: propTypes.string,
        onComplete: propTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            message: '',
            errors: [],
            is_submitting: false,
            was_submitted: false,
            error: null
        };
    }

    onSubmit = () => {
        const { is_submitting, email, first_name, last_name, phone_number, message } = this.state;
        if (is_submitting) return;
        this.setState({is_submitting: true}, () => {
            const errors = [];
            if (!email) errors.push('email');
            if (!first_name) errors.push('first_name');
            if (!last_name) errors.push('last_name');
            if (errors.length > 0) return this.setState({errors, error: 'Please fill out all required fields.', is_submitting: false});

            axios.post('https://api.hsforms.com/submissions/v3/integration/submit/9260404/a97690b2-c64e-49d1-9626-bfea95368016', {
                fields: [{
                    name: 'email',
                    value: email
                }, {
                    name: 'firstname',
                    value: first_name
                }, {
                    name: 'lastname',
                    value: last_name
                }, {
                    name: 'phone',
                    value: phone_number
                }, {
                    name: 'message',
                    value: message
                }],
                context: {
                    pageName: this.props.page_name
                }
            })
                .then(() => {
                    return this.setState({
                        was_submitted: true,
                        is_submitting: false,
                        error: null,
                        errors: [],
                        email: '',
                        first_name: '',
                        last_name: '',
                        phone_number: '',
                        message: ''
                    }, () => {
                        return setTimeout(this.props.onComplete, 3000);
                    });
                })
                .catch(error => {
                    return this.setState({errors: [], error: 'Please enter a valid email address (required) and phone number (optional).', is_submitting: false});
                });
        });
    }

    onChange = (field, value) => {
        const errors = this.state.errors.slice(); // make a copy
        const error_index = errors.indexOf(field);
        if (value.length > 0 && error_index !== -1) errors.splice(error_index, 1); // clear error when text inputted
        if (value.length === 0 && error_index === -1) errors.push(field); // add error if text removed
        const new_state = {errors};
        new_state[field] = value;
        return this.setState(new_state);
    }

    render() {
        const { error, errors, is_submitting, was_submitted, email, first_name, last_name, phone_number, message } = this.state;

        if (was_submitted) {
            return (
                <div className='contactForm'>
                    <div className='successMessage'>Thank you! We will get in contact with you soon.</div>
                </div>
            );
        }

        return (
            <div className='contactForm'>
                {error && <div className='errorMessage'>{error}</div>}
                {!error && <div className='message'>We'd love to hear from you!</div>}
                <div className='formContainer bottomSpace'>
                    <label className={errors.indexOf('email') !== -1 ? 'error' : ''} htmlFor='email'>Email Address<span className='req'>*</span></label>
                    <input
                        className={errors.indexOf('email') !== -1 ? 'error' : ''}
                        id='email'
                        type='text'
                        value={email}
                        inputMode='email'
                        onChange={event => this.onChange('email', event.target.value)} />
                </div>
                <div className='formContainer bottomSpace'>
                    <label className={errors.indexOf('first_name') !== -1 ? 'error' : ''} htmlFor='firstname'>First Name<span className='req'>*</span></label>
                    <input
                        className={errors.indexOf('first_name') !== -1 ? 'error' : ''}
                        id='firstname'
                        type='text'
                        value={first_name}
                        inputMode='text'
                        onChange={event => this.onChange('first_name', event.target.value)} />
                </div>
                <div className='formContainer bottomSpace'>
                    <label className={errors.indexOf('last_name') !== -1 ? 'error' : ''} htmlFor='lastname'>Last Name<span className='req'>*</span></label>
                    <input
                        className={errors.indexOf('last_name') !== -1 ? 'error' : ''}
                        id='lastname'
                        type='text' value={last_name}
                        inputMode='text'
                        onChange={event => this.onChange('last_name', event.target.value)} />
                </div>
                <div className='formContainer bottomSpace'>
                    <label htmlFor='phone'>Phone Number</label>
                    <input
                        type='text' value={phone_number}
                        id='phone'
                        inputMode='tel'
                        onChange={event => this.setState({phone_number: event.target.value})} />
                </div>
                <div className='formContainer bottomSpace'>
                    <label htmlFor='phone'>Message</label>
                    <textarea
                        value={message}
                        inputMode='text'
                        onChange={event => this.setState({message: event.target.value})} />
                </div>
                <div className='buttonContainer'>
                    <Button text={is_submitting ? 'Submitting...' : 'Submit'} onClick={this.onSubmit} />
                </div>
            </div>
        );
    }
}
