import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/ContactUs.css';
import Button from 'components/Button';

export default class ContactUs extends Component {
    static propTypes = {
        page_name: PropTypes.string,
        display_greyscale: PropTypes.bool
    };

    render() {
        const { display_greyscale, page_name} = this.props;

        return (
            <div className={'contactUs' + (display_greyscale ? ' greyscale' : '')}>
                <h1>Have questions?</h1>
                <h1>We have answers.</h1>
                <div className='subTitle'>Contact us to learn more about what we offer.</div>
                <Button type='inverted' text='Contact Us' uri={`/contact?referrer=${page_name}`} />
            </div>
        );
    }
}
