import React, { Component } from 'react';
import 'static/css/ContactView.css';
import ContactForm from 'components/ContactForm';

export default class ContactView extends Component {
    constructor(props) {
        super(props);

        let referrer = 'header';
        if (this.props.location.search && this.props.location.search.indexOf('?referrer=' !== -1)) {
            referrer = this.props.location.search.slice(10, this.props.location.search.length);
        }

        this.state = {
            referrer
        };
    }
    render() {
        const { referrer } = this.state;

        return (
            <div className='contactView'>
                <ContactForm page_name={referrer} />
            </div>
        );
    }
}
