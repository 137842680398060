import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';
import Platforms from 'components/Platforms';

import hero_image from 'static/images/set_top_box_hero.jpg';
import set_top_box_image from 'static/images/set_top_box.jpg';
import face_icon from 'static/images/icons/facial_recognition.svg';
import form_icon from 'static/images/icons/form.svg';
import gesture_icon from 'static/images/icons/gesture.svg';
import tracking_icon from 'static/images/icons/tracking.svg';
import body_thumbnail from 'static/images/thumbnails/body.jpg';
import gesture_thumbnail from 'static/images/thumbnails/gesture.jpg';
import face_recognition_thumbnail from 'static/images/thumbnails/face_recognition.jpg';
import camera_operator_thumbnail from 'static/images/thumbnails/camera_operator.jpg';

const videos = {
    person_detection: 'https://www.youtube.com/embed/Dds3bY-y1I8',
    pose: 'https://www.youtube.com/embed/gJ9t_6N-cdE',
    face_recognition: 'https://www.youtube.com/embed/3Adkzexk8GM',
    camera_operator: 'https://www.youtube.com/embed/dZIdspQDCB0'
};

export default class SetTopBoxView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='setTopBoxView'>
                <Hero
                    page_name='set_top_box'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Camera', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Set-Top Box Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite SoC with a simple software update.'
                    contents={[{
                        title: 'Person Detection',
                        description: 'Detects all people in view of the camera.',
                        image: body_thumbnail,
                        onClick: () => this.setState({watching_video: 'person_detection'})
                    }, {
                        title: 'camera_operator',
                        description: 'Keeps user framed regardless of other people in view.',
                        image: camera_operator_thumbnail,
                        onClick: () => this.setState({watching_video: 'camera_operator'})
                    }, {
                        title: 'Face Recognition',
                        description: 'Enables two-factor authentication to ensure the proper users are accessing content.',
                        image: face_recognition_thumbnail,
                        onClick: () => this.setState({watching_video: 'face_recognition'})
                    }, {
                        title: 'Pose Estimation',
                        description: 'Integrates gesture controls for users to quickly enable or disable features.',
                        image: gesture_thumbnail,
                        onClick: () => this.setState({watching_video: 'pose'})
                    }]} />
                <BulletList
                    title='Key Value Differentiation'
                    subtitle='Pilot AI has built a completely custom framework for computer vision optimized for edge processing. This optimization, combined with Pilot AI’s lightweight memory footprint, has enabled Pilot AI deployments across multiple industries and use cases.'
                    image={set_top_box_image}
                    items={[
                        'Run all inference at the edge for reduced latency and improved user privacy',
                        'Lower BOM cost by utilizing lower-cost chipsets for computer vision intelligence',
                        'Deploy easily with no third-party dependencies like TensorFlow, OpenCV, or PyTorch',
                        'Scale easily with your product roadmap using Pilot AI’s stackable, plug-and-play analytics',
                        'Have confidence in technology that is utilized by top providers of set-top boxes'
                    ]} />
                <Icons
                    title='Set-Top Box Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intelligent Tracking',
                        description: 'Follow users as they move around a room and focus on them as they speak.',
                        image: tracking_icon
                    }, {
                        title: 'Background Blur',
                        description: 'Focus on users and blur the background to minimize distractions.',
                        image: form_icon
                    }, {
                        title: 'Content Control',
                        description: 'Recognize users for greater control of specific content.',
                        image: face_icon
                    }, {
                        title: 'Gesture Control',
                        description: 'Take advantage of key features without a remote control.',
                        image: gesture_icon
                    }]} />
                <Platforms />
                <ContactUs page_name='set_top_box' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
