import React, { Component } from 'react';
import 'static/css/Platforms.css';

export default class Platforms extends Component {
    render() {
        return (
            <div className='platforms'>
                <h1>Integration Specifications</h1>
                <div className='platformsContent'>
                    <div className='listsContainer'>
                        <div className='listContainer'>
                            <h4>Supported Platforms</h4>
                            <ul>
                                <li>Ambarella</li>
                                <li>Arm</li>
                                <li>CUDA - NVIDIA</li>
                                <li>Ingenic</li>
                                <li>iCatch</li>
                                <li>NXP</li>
                                <li>Qualcomm</li>
                                <li>Sigmastar</li>
                                <li>Socionext</li>
                                <li>Synaptics</li>
                                <li>x86</li>
                            </ul>
                        </div>
                        <div className='listContainer'>
                            <h4>Supported Operating Systems</h4>
                            <ul>
                                <li>Android</li>
                                <li>iOS</li>
                                <li>Linux</li>
                                <li>Mac OS</li>
                                <li>OpenWRT</li>
                                <li>RTOS/Mbed/Bare metal</li>
                            </ul>
                        </div>
                        <div className='listContainer'>
                            <h4>Hardware Requirements</h4>
                            <ul>
                                <li>Memory: 17MB (processing 640x480)</li>
                                <li>{'ROM: <10MB'}</li>
                            </ul>
                            <h4>Camera Field of View</h4>
                            <ul>
                                <li>45° - 180°</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
