import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'static/css/SolutionsView.css';
import Image from 'components/Image';
import automotive from 'static/images/solution_rows/automotive_row.jpg';
import fitness from 'static/images/solution_rows/fitness_row.jpg';
import government from 'static/images/solution_rows/government_row.jpg';
import smart_home from 'static/images/solution_rows/smart_home_row.jpg';
import surveillance from 'static/images/solution_rows/surveillance_row.jpg';

export default class SolutionsView extends Component {
    renderRow(title, class_name, path, image) {
        return (
            <Link to={path} className={`solutionsRow ${class_name}`}>
                <Image src={image} alt={title} />
                <div className='gradientOverlay' />
                <div className='rowTitle'>{title}</div>
            </Link>
        );
    }
    render() {
        return (
            <div className='solutionsView'>
                {this.renderRow('Surveillance', 'surveillance','/solution/surveillance', surveillance)}
                {this.renderRow('Automotive', 'automotive', '/solution/automotive', automotive)}
                {this.renderRow('Home Fitness', 'fitness', '/solution/fitness', fitness)}
                {this.renderRow('Smart Home', 'smartHome', '/solution/smarthome', smart_home)}
                {this.renderRow('Government', 'government', '/solution/government', government)}
            </div>
        );
    }
}
