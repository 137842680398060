import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/Icons.css';
import Image from 'components/Image';

export default class Icons extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        icons: PropTypes.array // like [{title: '', description: '', image: ''}]
    };

    render() {
        const { title, subtitle, icons } = this.props;
        let max_width = '1200px'; // fits 4 icons wide by default
        if (icons.length % 4 <= 2 && icons.length % 4 > 0) max_width = '1000px'; // if it'll fit better as a 3x3 grid do that
        return (
            <div className='icons'>
                <h1>{title}</h1>
                <div className='subTitle'>{subtitle}</div>
                <div className='iconsContainer' style={{maxWidth: max_width}}>{icons.map((icon, index) => (
                    <div className='icon' key={index}>
                        <Image src={icon.image} alt={icon.title} />
                        <div className='title'>{icon.title}</div>
                        <div className='description'>{icon.description}</div>
                    </div>
                ))}</div>
            </div>
        );
    }
}
