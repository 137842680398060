import React, { Component } from 'react';
import 'static/css/ParticleNetwork.css';

const OPTIONS = {
    velocity: 1,
    density: 15000,
    max_density: 8000,
    net_line_distance: 200,
    net_line_color: '#dddddd',
    particle_color: '#ffffff',
    radius_min: 1.5,
    radius_max: 2.5,
    spawn_quantity: 3
};

export default class ParticleNetwork extends Component {
    constructor(props) {
        super(props);
        this.particles = [];
        this.mouse_particle = null;
        this.mouse_is_down = false;
        this.state = {
            width: 0,
            height: 0
        };
    }

    componentDidMount() {
        this.updateSize(() => {
            window.addEventListener('resize', this.onWindowResize);
            this.createParticles(true);
            this.animation_frame = requestAnimationFrame(this.update);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        window.cancelAnimationFrame(this.animation_frame);
    }

    clearCreateInterval() {
        if (this.create_interval) {
            clearInterval(this.create_interval);
            this.create_interval = null;
        }
    }

    clearSpawnInterval() {
        if (this.spawn_interval) {
            clearInterval(this.spawn_interval);
            this.spawn_interval = null;
        }
    }

    onWindowResize = () => {
        const context = this.refs.canvas.getContext('2d');
        context.clearRect(0, 0, this.state.width, this.state.height);
        this.updateSize(this.createParticles);
    }

    onMouseMove = event => {
        if (!this.mouse_particle) this.createMouseParticle();
        this.mouse_particle.x = event.nativeEvent.offsetX;
        this.mouse_particle.y = event.nativeEvent.offsetY;
    }

    onMouseDown = () => {
        this.is_clicking = true;
        let counter = 0;
        let quantity = OPTIONS.spawn_quantity;
        this.spawn_interval = setInterval(() => {
            if (this.is_clicking) {
                if (counter === 1) quantity = 1;
                for (let i = 0; i < quantity; i++) {
                    if (this.mouse_particle) this.particles.push(this.createParticle(this.mouse_particle.x, this.mouse_particle.y));
                }
            } else {
                this.clearSpawnInterval();
            }
            counter++;
        }, 100);
    }

    onMouseUp = () => {
        this.is_clicking = false;
    }

    onMouseOut = () => {
        this.removeMouseParticle();
    }

    updateSize = callback => {
        const { width, height } = this.refs.canvas.getBoundingClientRect();
        this.setState({width, height}, callback);
    }

    createParticle = (x, y) => {
        const { width, height } = this.state;

        const particle = {
            color: OPTIONS.particle_color,
            radius: Math.random() * (OPTIONS.radius_max - OPTIONS.radius_min) + OPTIONS.radius_min,
            opacity: 0,
            x: x || Math.random() * width,
            y: y || Math.random() * height,
            dying: false,
            velocity: {
                x: (Math.random() - 0.5) * OPTIONS.velocity,
                y: (Math.random() - 0.5) * OPTIONS.velocity
            }
        };

        return particle;
    }

    createParticles = is_initial => {
        const { width, height } = this.state;
        const quantity = width * height / OPTIONS.density;

        if (is_initial) {
            let counter = 0;
            this.clearCreateInterval();
            this.create_interval = setInterval(() => {
                if (counter < quantity - 1) this.particles.push(this.createParticle());
                else this.clearCreateInterval();
                counter++;
            }, 80);
        } else {
            const particles = [];
            for (let i = 0; i < quantity; i++) particles.push(this.createParticle());
            this.particles = particles;
        }
    }

    createMouseParticle = () => {
        this.mouse_particle = this.createParticle();
        this.mouse_particle.velocity = {x: 0, y: 0};
        this.mouse_particle.opacity = 1;
        this.particles.push(this.mouse_particle);
        return this.mouse_particle;
    }

    removeMouseParticle = () => {
        const index = this.particles.indexOf(this.mouse_particle);
        if (index === -1) return;
        this.particles.splice(index, 1);
        this.mouse_particle = null;
    }

    updateParticle = particle => {
        const { width, height } = this.state;
        if (particle.dying && particle.opacity > 0.01) particle.opacity -= 0.01;
        else if (particle.dying) particle.opacity = 0;
        else if (particle.opacity < 1) particle.opacity += 0.01;
        else particle.opacity = 1;
        if (particle.x > width + 100 || particle.x < -100) particle.velocity.x = -particle.velocity.x;
        if (particle.y > height + 100 || particle.y < -100) particle.velocity.y = -particle.velocity.y;
        particle.x += particle.velocity.x;
        particle.y += particle.velocity.y;
    }

    update = () => {
        const { width, height } = this.state;
        const context = this.refs.canvas.getContext('2d');
        context.clearRect(0, 0, width, height);
        context.globalAlpha = 1;

        // start to kill particles past max
        const max_quantity = width * height / OPTIONS.max_density;
        if (this.particles.length > max_quantity) {
            const num_particles_to_remove = this.particles.length % max_quantity;
            for (let i = 0; i < num_particles_to_remove; i++) this.particles[i].dying = true;
        }

        // draw connections
        for (let i = 0; i < this.particles.length; i++) {
            for (let j = this.particles.length - 1; j > i; j--) {
                const p1 = this.particles[i];
                const p2 = this.particles[j];
                let distance;
                // simple measure first for speed
                distance = Math.min(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));
                if (distance > OPTIONS.net_line_distance) continue;
                // precise measure now
                distance = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
                if (distance > OPTIONS.net_line_distance) continue;

                context.beginPath();
                context.strokeStyle = OPTIONS.net_line_color;
                context.globalAlpha = (OPTIONS.net_line_distance - distance) / OPTIONS.net_line_distance * p1.opacity * p2.opacity;
                context.lineWidth = 0.7;
                context.moveTo(p1.x, p1.y);
                context.lineTo(p2.x, p2.y);
                context.stroke();
            }
        }

        // draw particles
        const indices_to_remove = [];
        this.particles.forEach((particle, index) => {
            this.updateParticle(particle);
            if (particle.dying && particle.opacity <= 0) indices_to_remove.push(index);
            else this.drawParticle(particle);
        });
        indices_to_remove.reverse().forEach(index => this.particles.splice(index, 1)); // remove in reverse order so indices don't change

        if (OPTIONS.velocity !== 0) this.animation_frame = window.requestAnimationFrame(this.update);
    }

    drawParticle = particle => {
        const context = this.refs.canvas.getContext('2d');
        context.beginPath();
        context.fillStyle = particle.color;
        context.globalAlpha = particle.opacity;
        context.arc(particle.x, particle.y, particle.radius, 0, 2 * Math.PI);
        context.fill();
    }

    render() {
        const { width, height } = this.state;
        return (
            <canvas
                className='particleNetwork'
                ref='canvas'
                width={width}
                height={height}
                onMouseMove={this.onMouseMove}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseOut={this.onMouseOut}>
            </canvas>
        );
    }
}
