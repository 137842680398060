import React, { Component } from 'react';
import 'static/css/GovernmentView.css';

import ContactUs from 'components/ContactUs';
import Image from 'components/Image';

import hero_image from 'static/images/government_hero.jpg';
import pillar1 from 'static/images/government/land.jpg';
import pillar2 from 'static/images/government/air.jpg';
import pillar3 from 'static/images/government/sea.jpg';
import takeoff from 'static/images/government/takeoff.jpg';

export default class GovernmentView extends Component {
    render() {
        return (
            <div className='governmentView'>
                <div className='governmentHero'>
                    <Image src={hero_image} alt='satelite' />
                </div>
                <div className='whatWeDo'>
                    <h1>What We Do</h1>
                    <div className='whatWeDoText'>Pilot AI builds best-in-world analytics tools for our service men and women, using <span className='highlighted'>machine vision</span> to distill abundant sensor data into concise <span className='highlighted'>intelligence</span>. Our real-time insights empower personnel to focus on <span className='highlighted'>critical decision making</span>.</div>
                </div>
                <div className='pillars'>
                    <div className='pillarContainer'><Image src={pillar1} alt='Government Image' /></div>
                    <div className='pillarContainer'><Image src={pillar2} alt='Government Image' /></div>
                    <div className='pillarContainer'><Image src={pillar3} alt='Government Image' /></div>
                </div>
                <div className='reliable'>
                    <div className='reliableTextContainer'>
                        <div className='reliableHeader'>Reliable Software</div>
                        <div className='reliableText'>We recognize the critical importance of creating reliable computer vision software for utilization in the field and work closely with our partners to implement strategic initiatives.</div>
                    </div>
                </div>
                <div className='deployment'>
                    <Image src={takeoff} alt='Plane Taking Off' />
                    <div className='overlay'>
                        <div className='deploymentTextContainer'>
                            <div className='deploymentHeader'>Deployable Solutions</div>
                            <div className='deploymentText'>Our technology is built for plug-and-play deployment within multiple scenarios for national security, including deployment onboard true edge hardware operating in contested or denied environments.  Secure, customized model training is also available for sensitive mission applications.</div>
                            <div className='deploymentSubText'>Deployments for air, sea, land, and space.</div>
                        </div>
                    </div>
                </div>
                <ContactUs page_name='government' display_greyscale={true} />
            </div>
        );
    }
}
