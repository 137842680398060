import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/Hero.css';
import Button from 'components/Button';
import Image from 'components/Image';

export default class Hero extends Component {
    static propTypes = {
        page_name: PropTypes.string,
        text: PropTypes.array, // array of strings like [{text: 'hello hello'}, {text: 'hello', highlighted: true}, {text: 'hello'}]
        subText: PropTypes.string,
        image: PropTypes.string
    };

    render() {
        const { text, subText, image } = this.props;
        return (
            <div className='hero'>
                <Image src={image} alt='hero image' />
                <div className='darken' />
                <div className='text'>
                    <div className='textContents'>{text.map((text_entry, index) => (
                        <span key={index} className={text_entry.highlighted ? 'highlighted' : ''}>{text_entry.text}{index < text.length - 1 ? ' ' : ''}</span>
                    ))}</div>
                    <div className='subText'>{subText}</div>
                    <Button text='Request a Demo' uri={`/contact?referrer=${this.props.page_name}`} />
                </div>
            </div>
        );
    }
}
