import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'static/css/Button.css';

export default class Button extends Component {
    static propTypes = {
        text: PropTypes.string,
        onClick: PropTypes.func,
        uri: PropTypes.string, // for internal uri, will override onClick
        url: PropTypes.string, // for external links, will override onClick
        size: PropTypes.oneOf(['small', 'large']),
        type: PropTypes.oneOf(['primary', 'secondary', 'inverted', 'cancel', 'information', 'disabled'])
    };

    static defaultProps = {
        onClick: () => {},
        size: 'large',
        type: 'primary'
    };

    render() {
        const {
            onClick,
            size,
            text,
            type,
            uri,
            url
        } = this.props;

        let buttonClass = 'button ' + type;

        switch (size) {
        case 'small':
            buttonClass += ' small';
            break;
        default:
            break;
        }

        let button;
        if (uri) {
            button = <Link className={buttonClass} to={uri}>{text}</Link>;
        } else if (url) {
            button = <a className={buttonClass} href={url} target='_blank' rel='noopener noreferrer'>{text}</a>;
        } else {
            button = <div className={buttonClass} onClick={onClick}>{text}</div>;
        }

        return button;
    }
}
