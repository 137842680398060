import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';
import Platforms from 'components/Platforms';

import hero_image from 'static/images/fitness_hero.jpg';
import yoga_pose_image from 'static/images/yoga_pose.jpg';
import face_icon from 'static/images/icons/facial_recognition.svg';
import form_icon from 'static/images/icons/form.svg';
import muscle_icon from 'static/images/icons/muscle.svg';
import tracking_icon from 'static/images/icons/tracking.svg';
import body_thumbnail from 'static/images/thumbnails/fitness_body.jpg';
import fitness_pose_thumbnail from 'static/images/thumbnails/fitness_pose.jpg';
import yoga_pose_thumbnail from 'static/images/thumbnails/yoga_pose.jpg';
import face_recognition_thumbnail from 'static/images/thumbnails/fitness_face_recognition.jpg';
import camera_operator_thumbnail from 'static/images/thumbnails/camera_operator.jpg';

const videos = {
    person_detection: 'https://www.youtube.com/embed/1w7FC0OVBBA',
    fitness_pose: 'https://www.youtube.com/embed/YJzKFSEeUJM',
    face_recognition: 'https://www.youtube.com/embed/hH5u3UDpX3A',
    camera_operator: 'https://www.youtube.com/embed/dZIdspQDCB0',
    yoga_pose: 'https://www.youtube.com/embed/zAZKDvcluhE'
};

export default class FitnessDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='fitnessDetailsView'>
                <Hero
                    page_name='fitness_details'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Fitness', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Fitness Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite SoC with a simple software update.'
                    contents={[{
                        title: 'Person Detection',
                        description: 'Detects and tracks an individual throughout their workout.',
                        image: body_thumbnail,
                        onClick: () => this.setState({watching_video: 'person_detection'})
                    }, {
                        title: 'Camera Operator',
                        description: 'Keeps user framed regardless of other persons in view.',
                        image: camera_operator_thumbnail,
                        onClick: () => this.setState({watching_video: 'camera_operator'})
                    }, {
                        title: 'User Recognition',
                        description: 'Recognizes individuals before and during their workout.',
                        image: face_recognition_thumbnail,
                        onClick: () => this.setState({watching_video: 'face_recognition'})
                    }, {
                        title: 'Strength Training',
                        description: 'Tracks key points for proper positioning and personalized coaching.',
                        image: fitness_pose_thumbnail,
                        onClick: () => this.setState({watching_video: 'fitness_pose'})
                    }, {
                        title: 'Yoga and Stretching',
                        description: 'Detects pose for form coaching and real-time guidance.',
                        image: yoga_pose_thumbnail,
                        onClick: () => this.setState({watching_video: 'yoga_pose'})
                    }]} />
                <BulletList
                    title='Key Value Differentiation'
                    subtitle='Pilot AI has built a completely custom framework for computer vision optimized for edge processing. This optimization, combined with Pilot AI’s lightweight memory footprint, has enabled Pilot AI deployments across multiple industries and use cases.'
                    image={yoga_pose_image}
                    items={[
                        'Run all inference at the edge for reduced latency and improved user privacy',
                        'Lower BOM cost by utilizing lower-cost chipsets for computer vision intelligence',
                        'Deploy easily with no third-party dependencies like TensorFlow, OpenCV, or PyTorch',
                        'Scale easily with your product roadmap using Pilot AI’s stackable, plug-and-play analytics',
                        'Have confidence in technology that is utilized by top providers in the home fitness industry'
                    ]} />
                <Icons
                    title='Fitness Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intelligent Tracking',
                        description: 'Focuses capabilities on the user, not others in the background.',
                        image: tracking_icon
                    }, {
                        title: 'User Customization',
                        description: 'Recognizes users for personalized routines and statistics.',
                        image: face_icon
                    }, {
                        title: 'Repetition Counting',
                        description: 'Counts repetitions needed to keep users on track toward their goals.',
                        image: muscle_icon
                    }, {
                        title: 'Form Monitoring',
                        description: 'Estimates proper form during exercise with up to 38 key points.',
                        image: form_icon
                    }]} />
                <Platforms />
                <ContactUs page_name='fitness_details' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
