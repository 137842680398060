import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';

import hero_image from 'static/images/camera_hero.jpg';
import camera_image from 'static/images/camera.jpg';
import intruder_icon from 'static/images/icons/intruder.svg';
import package_icon from 'static/images/icons/package.svg';
import face_icon from 'static/images/icons/facial_recognition.svg';
import distancing_icon from 'static/images/icons/social_distancing.svg';
import lpr_icon from 'static/images/icons/lpr.svg';
import intrusion_thumbnail from 'static/images/thumbnails/home_intrusion.jpg';
import mapping_thumbnail from 'static/images/thumbnails/mapping.jpg';
import pet_thumbnail from 'static/images/thumbnails/pet.jpg';
import vehicle_thumbnail from 'static/images/thumbnails/surveillance_vehicle.jpg';

const videos = {
    intrusion: 'https://www.youtube.com/embed/osOQLp2drpc',
    pet: 'https://www.youtube.com/embed/fZV6KnZzXxM',
    mapping: 'https://www.youtube.com/embed/DGTTv73FmFw',
    vehicle: 'https://www.youtube.com/embed/2xN4cApESy0'
};

export default class SurveillanceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='surveillanceView'>
                <Hero
                    page_name='surveillance'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Surveillance', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Surveillance Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite camera SoC with a simple software update.'
                    contents={[{
                        title: 'Person and Face Detection',
                        description: 'Detects attempted theft with limited compute footprint.',
                        image: intrusion_thumbnail,
                        onClick: () => this.setState({watching_video: 'intrusion'})
                    }, {
                        title: 'Projection Mapping',
                        description: 'Detects, tracks, and projects the path of people in a facility.',
                        image: mapping_thumbnail,
                        onClick: () => this.setState({watching_video: 'mapping'})
                    }, {
                        title: 'Vehicle Detection',
                        description: 'Detects vehicles, alerting you when vehicles are present.',
                        image: vehicle_thumbnail,
                        onClick: () => this.setState({watching_video: 'vehicle'})
                    }, {
                        title: 'Pet Detection',
                        description: 'Differentiates between people and pets, significantly reducing false alarms.',
                        image: pet_thumbnail,
                        onClick: () => this.setState({watching_video: 'pet'})
                    }]} />
                <BulletList
                    title='Seamless Integration'
                    subtitle='Our proven SDK has been deployed globally by Fortune 500 companies. It’s easy to get started.'
                    image={camera_image}
                    items={[
                        'Limited impact on battery or power',
                        'Small footprint - as low as 128 kB, and up to 60 MB',
                        'Customizable',
                        'Easy integration',
                        'Embedded, on-premise, and cloud-capable',
                        'Minimal to no impact on camera CPU & GPU'
                    ]} />
                <Icons
                    title='Surveillance Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intrusion Detection',
                        description: 'Detects attempted theft with limited impact on camera battery.',
                        image: intruder_icon
                    }, {
                        title: 'Package Alerts',
                        description: 'Notifies when packages are delivered to your designated location.',
                        image: package_icon
                    }, {
                        title: 'Vehicle Recognition',
                        description: 'Scans license plates for additional security and monitoring.',
                        image: lpr_icon
                    }, {
                        title: 'Social Distancing',
                        description: 'Detects when people are gathering too close to one another.',
                        image: distancing_icon
                    }, {
                        title: 'Access Control',
                        description: 'Recognizes individuals for access control or home monitoring.',
                        image: face_icon
                    }]} />
                {/* <FounderBio /> */}
                <ContactUs page_name='surveillance' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
