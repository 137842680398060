import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';

import hero_image from 'static/images/fitness_hero.jpg';
import smart_mirror_image from 'static/images/smart_mirror.jpg';
import face_icon from 'static/images/icons/facial_recognition.svg';
import muscle_icon from 'static/images/icons/muscle.svg';
import tracking_icon from 'static/images/icons/tracking.svg';
import body_thumbnail from 'static/images/thumbnails/body.jpg';
import pose_thumbnail from 'static/images/thumbnails/pose.jpg';
import face_recognition_thumbnail from 'static/images/thumbnails/face_recognition.jpg';
import camera_operator_thumbnail from 'static/images/thumbnails/camera_operator.jpg';

const videos = {
    person_detection: 'https://www.youtube.com/embed/Dds3bY-y1I8',
    pose: 'https://www.youtube.com/embed/UrGAivWeWMo',
    face_recognition: 'https://www.youtube.com/embed/3Adkzexk8GM',
    camera_operator: 'https://www.youtube.com/embed/dZIdspQDCB0'
};

export default class FitnessView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='fitnessView'>
                <Hero
                    page_name='fitness'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Fitness', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Fitness Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite SoC with a simple software update.'
                    contents={[{
                        title: 'Person and Face Detection',
                        description: 'Detects and tracks an individual throughout their workout.',
                        image: body_thumbnail,
                        onClick: () => this.setState({watching_video: 'person_detection'})
                    }, {
                        title: 'Pose Estimation',
                        description: 'Estimates poses for proper positioning and coaching.',
                        image: pose_thumbnail,
                        onClick: () => this.setState({watching_video: 'pose'})
                    }, {
                        title: 'User Recognition',
                        description: 'Recognizes individuals before they begin a workout.',
                        image: face_recognition_thumbnail,
                        onClick: () => this.setState({watching_video: 'face_recognition'})
                    }, {
                        title: 'Camera Operator',
                        description: 'Frames individuals as they work out for more accurate results.',
                        image: camera_operator_thumbnail,
                        onClick: () => this.setState({watching_video: 'camera_operator'})
                    }]} />
                <BulletList
                    title='Seamless Integration'
                    subtitle='Our proven SDK has been deployed globally by Fortune 500 companies. It’s easy to get started.'
                    image={smart_mirror_image}
                    items={[
                        'Limited impact on battery or power',
                        'Small footprint - as low as 128 kB, and up to 60 MB',
                        'Customizable',
                        'Easy integration',
                        'Embedded, on-premise, and cloud-capable',
                        'Minimal to no impact on CPU & GPU'
                    ]} />
                <Icons
                    title='Fitness Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intelligent Tracking',
                        description: 'Focuses capabilities on the user, not others in the background.',
                        image: tracking_icon
                    }, {
                        title: 'User Customization',
                        description: 'Recognizes users for personalized routines and statistics.',
                        image: face_icon
                    }, {
                        title: 'Repetition Counting',
                        description: 'Counts repetitions needed to keep users on track toward their goals.',
                        image: muscle_icon
                    }]} />
                {/* <FounderBio /> */}
                <ContactUs page_name='fitness' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
