import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';

import hero_image from 'static/images/car_hero.jpg';
import car_dash_image from 'static/images/car_dash.jpg';
import alarm_icon from 'static/images/icons/car_alarm.svg';
import tailgating_icon from 'static/images/icons/car_tailgating.svg';
import driver_icon from 'static/images/icons/driver.svg';
import blindspot_icon from 'static/images/icons/blindspot.svg';
import blindspot_thumbnail from 'static/images/thumbnails/vehicle_blindspot.jpg';
import driver_awareness_thumbnail from 'static/images/thumbnails/driver_awareness.jpg';
import face_thumbnail from 'static/images/thumbnails/vehicle_face_detection.jpg';
import intrusion_thumbnail from 'static/images/thumbnails/vehicle_intrusion.jpg';

const videos = {
    face_detection: 'https://www.youtube.com/embed/h2svkPDL5N0',
    intrusion: 'https://www.youtube.com/embed/cYJgHyOYuNI',
    driver_awareness: 'https://www.youtube.com/embed/YiiM6OewoFM',
    blindspot: 'https://www.youtube.com/embed/ReJdwXlUAUA'
};

export default class AutomotiveView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='automotiveView'>
                <Hero
                    page_name='automotive'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Automotive', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Automotive Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite automotive SoC with a simple software update.'
                    contents={[{
                        title: 'Face Recognition',
                        description: 'Recognizes driver\'s face for added vehicle security.',
                        image: face_thumbnail,
                        onClick: () => this.setState({watching_video: 'face_detection'})
                    }, {
                        title: 'Person Detection',
                        description: 'Detects attempted thefts with limited compute footprint.',
                        image: intrusion_thumbnail,
                        onClick: () => this.setState({watching_video: 'intrusion'})
                    }, {
                        title: 'Pose Detection',
                        description: 'Analyzes key points on driver\'s face to gauge awareness.',
                        image: driver_awareness_thumbnail,
                        onClick: () => this.setState({watching_video: 'driver_awareness'})
                    }, {
                        title: 'Vehicle Detection',
                        description: 'Recognizes vehicles entering your blindspot while driving.',
                        image: blindspot_thumbnail,
                        onClick: () => this.setState({watching_video: 'blindspot'})
                    }]} />
                <BulletList
                    title='Seamless Integration'
                    subtitle='Our proven SDK has been deployed globally by Fortune 500 companies. It’s easy to get started.'
                    image={car_dash_image}
                    items={[
                        'Minimal to no impact on infotainment system CPU or GPU',
                        'Small footprint - as low as 128 kB, and up to 60 MB',
                        'Support for single- or multi-camera streams',
                        'C++ API, with support for Linux, Android, and RTOS',
                        'Dedicated support from our engineering team to keep your project on schedule'
                    ]} />
                <Icons
                    title='Automotive Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intrusion Detection',
                        description: 'Detects attempted theft with limited impact on automotive battery.',
                        image: alarm_icon
                    }, {
                        title: 'Tailgating Alerts',
                        description: 'Alerts driver when beginning to get too close to a leading vehicle.',
                        image: tailgating_icon
                    }, {
                        title: 'Driver Awareness',
                        description: 'Notifies driver when not paying close attention to the road.',
                        image: driver_icon
                    }, {
                        title: 'Blindspot Detection',
                        description: 'Alerts driver when another vehicle appears in their blindspot.',
                        image: blindspot_icon
                    }]} />
                {/* <FounderBio /> */}
                <ContactUs page_name='automotive' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
