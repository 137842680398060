import React, { Component } from 'react';

import BulletList from 'components/BulletList';
import ContactUs from 'components/ContactUs';
import DemoCards from 'components/DemoCards';
// import FounderBio from 'components/FounderBio';
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Modal from 'components/Modal';
import Partners from 'components/Partners';

import hero_image from 'static/images/smart_home_hero.jpg';
import set_top_box_image from 'static/images/set_top_box.jpg';
import intruder_icon from 'static/images/icons/intruder.svg';
import package_icon from 'static/images/icons/package.svg';
import face_icon from 'static/images/icons/facial_recognition.svg';
import tracking_icon from 'static/images/icons/tracking.svg';
import camera_operator_thumbnail from 'static/images/thumbnails/camera_operator.jpg';
import face_recognition_thumbnail from 'static/images/thumbnails/face_recognition.jpg';
import pet_thumbnail from 'static/images/thumbnails/pet.jpg';
import vehicle_thumbnail from 'static/images/thumbnails/surveillance_vehicle.jpg';

const videos = {
    vehicle: 'https://www.youtube.com/embed/2xN4cApESy0',
    pet: 'https://www.youtube.com/embed/fZV6KnZzXxM',
    camera_operator: 'https://www.youtube.com/embed/dZIdspQDCB0',
    face_recognition: 'https://www.youtube.com/embed/3Adkzexk8GM'
};

export default class SmartHomeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            watching_video: null
        };
    }

    render() {
        const { watching_video } = this.state;
        return (
            <div className='smartHomeView'>
                <Hero
                    page_name='smart_home'
                    image={hero_image}
                    text={[{text: 'Accelerating'}, {text: 'Home', highlighted: true}, {text: 'Intelligence'}]}
                    subText='Compute-efficient analytics that run on both legacy and next-generation hardware.' />
                <Partners />
                <DemoCards
                    title='Home Capabilities'
                    subtitle='Pilot AI’s proprietary innovations bring cutting-edge technology to your favorite SoC with a simple software update.'
                    contents={[{
                        title: 'Person and Vehicle Detection',
                        description: 'Detects people and vehicles in and around the home.',
                        image: vehicle_thumbnail,
                        onClick: () => this.setState({watching_video: 'vehicle'})
                    }, {
                        title: 'Camera Operator',
                        description: 'Crops around participants for teleconferencing.',
                        image: camera_operator_thumbnail,
                        onClick: () => this.setState({watching_video: 'camera_operator'})
                    }, {
                        title: 'Face Recognition',
                        description: 'Recognizes familiar faces coming to the door.',
                        image: face_recognition_thumbnail,
                        onClick: () => this.setState({watching_video: 'face_recognition'})
                    }, {
                        title: 'Pet Detection',
                        description: 'Detects typical household pets while you are away.',
                        image: pet_thumbnail,
                        onClick: () => this.setState({watching_video: 'pet'})
                    }]} />
                <BulletList
                    title='Seamless Integration'
                    subtitle='Our proven SDK has been deployed globally by Fortune 500 companies. It’s easy to get started.'
                    image={set_top_box_image}
                    items={[
                        'Limited impact on battery or power',
                        'Small footprint - as low as 128 kB, and up to 60 MB',
                        'Customizable',
                        'Easy integration',
                        'Embedded, on-premise, and cloud-capable',
                        'Minimal to no impact on camera CPU & GPU'
                    ]} />
                <Icons
                    title='Home Features'
                    subtitle='Leverage the power of Pilot AI’s capabilities to support your customer-centric use cases right at the edge.'
                    icons={[{
                        title: 'Intrusion Detection',
                        description: 'Detects attempted theft with limited impact on camera battery.',
                        image: intruder_icon
                    }, {
                        title: 'Package Alerts',
                        description: 'Notifies when packages are delivered to your designated location.',
                        image: package_icon
                    }, {
                        title: 'Familiar Faces',
                        description: 'Recognizes individuals for access control or home monitoring.',
                        image: face_icon
                    }, {
                        title: 'Teleconferencing',
                        description: 'Follows an individual around the room or frames an entire group.',
                        image: tracking_icon
                    }]} />
                {/* <FounderBio /> */}
                <ContactUs page_name='smart_home' />
                <Modal is_visible={!!watching_video} onClose={() => this.setState({watching_video: null})}>
                    {!!watching_video && <iframe style={{maxWidth: '100%'}} title='video' width='560' height='315' src={`${videos[watching_video]}?autoplay=1&rel=0&mute=1`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}></iframe>}
                </Modal>
            </div>
        );
    }
}
