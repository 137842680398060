import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'static/css/BulletList.css';
import Image from 'components/Image';

export default class BulletList extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        image: PropTypes.string,
        items: PropTypes.array // like ['', '', ...]
    };

    render() {
        const { title, subtitle, image, items } = this.props;
        return (
            <div className='bulletList'>
                <h1>{title}</h1>
                <div className='subTitle'>{subtitle}</div>
                <div className='content'>
                    <div className='imageContainer'>
                        <div className='imageContainerInner'><Image src={image} alt='title' /></div>
                    </div>
                    <div className='listContainer'>{items.map((item, index) => (
                        <div className='listItem' key={index}>
                            <div className='bullet'>&bull;</div>
                            <div className='text'>{item}</div>
                        </div>
                    ))}</div>
                </div>
            </div>
        );
    }
}
